import React from 'react'
import { ThemeProvider } from '@emotion/react'

const colors = {
  purple: '#7069FC',
  text: 'rgba(0,0,0,0.5)',
  buttonText: '#FFFFFF'
}

const theme = {
  colors
}

const DefaultThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default DefaultThemeProvider
